import { useEffect, useState } from "react";

import { getAccessToken } from "./getAccessToken";

export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    (async () => {
      const token = await getAccessToken();
      setAccessToken(token);
    })();
  }, []);

  return accessToken;
};
