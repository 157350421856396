import { WSNotificationResponse } from "../types";

const isNotification = (data: any): data is WSNotificationResponse => {
  return (
    typeof data === "object" &&
    typeof data.tid === "string" &&
    (data.loc === undefined ||
      (Array.isArray(data.loc) &&
        ((typeof data.loc[0] === "string" && typeof data.loc[1] === "string") ||
          (typeof data.loc[0] === "number" && typeof data.loc[1] === "number")))) &&
    typeof data.gwid === "string"
  );
};

export function assertIsWSNotification(data: unknown): asserts data is WSNotificationResponse {
  if (!isNotification(data)) {
    throw new Error("Received invalid notification");
  }
}
