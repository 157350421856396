import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import IconButton from "@mui/material/IconButton";
import { useCallback } from "react";
import { RaThemeOptions } from "react-admin";
import { useTheme } from "react-admin";

import { isThemeType } from "common";
import { darkTheme, theme } from "theme";

export const ToggleThemeButton = () => {
  const [currentTheme, setCurrentTheme] = useTheme();
  const isDark = isThemeType(currentTheme)
    ? currentTheme === "dark"
    : currentTheme?.palette?.mode === "dark";

  const handleTogglePaletteType = useCallback(() => {
    setCurrentTheme((isDark ? theme : darkTheme) as RaThemeOptions);
  }, [isDark, setCurrentTheme]);

  return (
    <IconButton
      onClick={handleTogglePaletteType}
      size="small"
      sx={{
        color: "inherit"
      }}
    >
      {isDark ? <LightModeOutlinedIcon color="action" /> : <DarkModeOutlinedIcon color="action" />}
    </IconButton>
  );
};
