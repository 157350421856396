import { ThemeOptions } from "@mui/material/styles";
import { defaultTheme } from "react-admin";

import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import { colors } from "./colors";

export const theme: ThemeOptions = {
  ...defaultTheme,
  palette: {
    secondary: defaultTheme.palette?.secondary,
    success: {
      main: colors.greenLight,
      contrastText: colors.greenLightContrast
    }
  },
  components: {
    ...defaultTheme.components,
    MuiFormControlLabel: {
      styleOverrides: {
        labelPlacementStart: {
          justifyContent: "space-between"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.blackText
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          fontWeight: "bold"
        },
        root: {
          border: "none",
          "--DataGrid-pinnedBackground": defaultTheme.palette?.background?.paper
        },
        filterForm: {
          alignItems: "baseline",
          WebkitAlignItems: "unset",
          ".MuiTextField-root": {
            display: "block",
            marginTop: 0
          },
          ".MuiDataGrid-filterFormLogicOperatorInput": {
            marginBottom: 8
          },
          ".MuiDataGrid-filterFormDeleteIcon": {
            marginBottom: 10
          }
        },
        pinnedColumns: {
          backgroundColor: "#FFFFFF",
          boxShadow: "none"
        },
        columnHeader: {
          border: "none"
        },
        cell: {
          borderLeft: "none",
          borderRight: "none"
        },
        virtualScroller: {
          "& .MuiDataGrid-filler--pinnedLeft": {
            borderRight: "none"
          },
          "& .MuiDataGrid-filler--pinnedRight": {
            borderLeft: "none"
          }
        }
      }
    }
  }
};

export const darkTheme: ThemeOptions = {
  ...theme,
  palette: {
    ...theme.palette,
    mode: "dark"
  },
  components: {
    ...theme.components,
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.white
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        ...theme.components?.MuiDataGrid?.styleOverrides,
        pinnedColumns: {
          backgroundImage: "none",
          backgroundColor: "transparent",
          boxShadow: "none"
        }
      }
    }
  }
};
