import { flow } from "lodash-es";
import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  SORT_ASC,
  UpdateManyParams,
  UpdateResult,
  RaRecord,
  CreateResult
} from "react-admin";

import { isFulfilled, isRejected } from "common";

import { getFilteredTrackersList } from "./helpers/getFilteredTrackersLIst";
import { getSortedTrackersList } from "./helpers/getSortedTrackersList";
import { isDuplicatedError } from "./helpers/isDuplicatedError";
import { mapTrackerResponse } from "./helpers/mapTrackerResponse";
import { mapTrackerFormValuesToRequest } from "../../modules/Trackers/TrackerEdit/utils/mapTrackerFormValuesToRequest";
import { httpClient } from "../../services";

import type { Tracker } from "./types";

const API_URL = process.env.REACT_APP_API_URL;
const RESOURCE_NAME = "trackers";

// Presence endpoint returns presence status only when gwid is provided
// It can be a random value
const ANY_GATEWAY_PARAM = "gwid=2";

export const DEFAULT_TRACKERS_SORT = { field: "recency", order: SORT_ASC } as const;

// Return types (e.g. Promise<GetListResult>) in the data provider are specified without generics like e.g. GetListResult<Tracker>
// This is because the passed RecordType would have to extend RaRecord (ra-core/src/types.ts)
// and RaRecord has `[key: string]: any;` in the interface definition
// It is safer to not have `[key: string]: any;` in all the types,
// as it makes the type cheaking weaker in object literals (one can set any key in the object definition)
export const trackersDataProvider: DataProvider = {
  getList: async (_, params: GetListParams): Promise<GetListResult> => {
    const lastSeenFilter =
      params.filter.lastSeenFrom && params.filter.lastSeenTo
        ? `&lastSeen=${params.filter.lastSeenFrom},${params.filter.lastSeenTo}`
        : "";

    const queryData: Tracker[] = await Promise.all([
      httpClient(`${API_URL}/${RESOURCE_NAME}?deleted=true${lastSeenFilter}`).then(
        ({ json }) => json
      ),
      httpClient(`${API_URL}/presence?${ANY_GATEWAY_PARAM}`).then(({ json }) => json)
    ]).then(([trackersData, presenceData]) =>
      trackersData.map((tracker: Tracker) => mapTrackerResponse(tracker, presenceData))
    );

    const result = flow(
      (data) =>
        getFilteredTrackersList(data, {
          ...params.filter
        }),
      (data) => getSortedTrackersList(data, params.sort)
    )(queryData);

    return {
      data: result,
      total: result.length
    };
  },

  getOne: async (_, params: GetOneParams): Promise<GetOneResult> => {
    const [trackerData, presenceData] = await Promise.all([
      httpClient(`${API_URL}/${RESOURCE_NAME}/${params.id}`).then(({ json }) => json),
      httpClient(`${API_URL}/presence?tid=${params.id}`).then(({ json }) => json)
    ]);

    const tracker: Tracker = mapTrackerResponse(trackerData, presenceData);

    return {
      data: tracker
    };
  },

  getMany: async (_, { ids }: GetManyParams): Promise<GetManyResult> => {
    const trackers: Tracker[] = await httpClient(`${API_URL}/${RESOURCE_NAME}`).then(({ json }) =>
      json.map((tracker: Tracker) => mapTrackerResponse(tracker))
    );

    const filteredTrackers = trackers.filter(({ id }) => ids.includes(id));

    return { data: filteredTrackers };
  },

  // TODO not implemented yet
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),

  create: (_, params): Promise<CreateResult> =>
    httpClient(`${API_URL}/${RESOURCE_NAME}`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id } as RaRecord
    })),

  createMany: (resource: string, params: CreateParams<Tracker[]>) => {
    return Promise.allSettled(
      params.data.map((tracker) =>
        httpClient(`${API_URL}/${RESOURCE_NAME}`, {
          method: "POST",
          body: JSON.stringify(tracker)
        })
      )
    ).then((responses) => {
      const success = responses.filter(isFulfilled).map((response) => response.value);

      const errors = responses
        .filter(isRejected)
        .filter((errorResponse) => !isDuplicatedError(errorResponse))
        .map((response) => response.reason?.body.error);

      const duplicates = responses
        .filter(isRejected)
        .filter(isDuplicatedError)
        .map((response) => response.reason?.body.error);

      return { data: { success, errors, duplicates } };
    });
  },

  update: (_, params): Promise<UpdateResult> =>
    httpClient(`${API_URL}/${RESOURCE_NAME}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({
      data: { ...mapTrackerResponse(json), isPresent: params.previousData?.isPresent }
    })),

  updateMany: (_, params: UpdateManyParams<Tracker[]>) => {
    return Promise.all(
      params.data.map((tracker) =>
        httpClient(`${API_URL}/${RESOURCE_NAME}/${tracker?.id}`, {
          method: "PUT",
          body: JSON.stringify(mapTrackerFormValuesToRequest(tracker))
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.tid) }));
  },

  delete: (_, params) => {
    return httpClient(`${API_URL}/${RESOURCE_NAME}/${params.id}`, {
      method: "DELETE"
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: async (_, params) => {
    const resultIds = await deleteMany(params.ids, false);

    return { data: resultIds };
  },

  forceDeleteMany: async (_: string, params: DeleteManyParams<Tracker>) => {
    const resultIds = await deleteMany(params.ids, true);

    return { data: resultIds };
  }
};

const deleteMany = async (ids: Identifier[], force?: boolean) => {
  const responses = await Promise.all(
    ids.map((id) =>
      httpClient(`${API_URL}/${RESOURCE_NAME}/${id}${force ? "?force=true" : ""}`, {
        method: "DELETE"
      })
    )
  );

  return responses.map((response) => response.json.tid);
};
